<template>
  <div class="box">
    <input
      class="time-input"
      :class="{ zIndex: isActive }"
      type="text"
      :id="id"
      placeholder=""
      v-on:blur="inputblur()"
      v-on:focus="inputfocus()"
    />
  </div>
</template>

<script>

import laydate from 'laydate-es6';
export default {
  props: ['date', 'index'],
  data() {
    return {
      id: 0,
      isActive: false
    }
  },
  methods: {
    inputfocus() {
      this.isActive = true
    },
    inputblur() {
      this.isActive = false
    },
    init() {
      let _this = this
      let dateInput = document.querySelector('#' + _this.id);
      laydate.render({
        elem: '#' + _this.id,
        value: _this.date,
        type: 'time',
        btns: ['now', 'confirm'],
        done: function(value, date, endDate) {
          _this.$emit('change', _this.index, value)
        }
      });
    }
  },
  mounted() {
    let num = Math.random() * 10000
    let num2 = num.toFixed(0)
    this.id = 'a' + num2

    this.$nextTick(function () {
      this.init()
    })
  }
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
}
.time-input {
  position: relative;
  // z-index: 99999999;
  font-size: 14px;
  width: 220px;
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  border-radius: 4px;
  display: inline-block;
  background-image: url('../img/time.png');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position-y: center;
  background-position-x: 10px;
}
.zIndex {
  z-index: 99999999;
}
</style>